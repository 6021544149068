<template>
  <div class="syslog">
    <el-row :gutter="0" class="search" align="middle" type="flex">
      <el-col :span="2"><span>时间查询:</span></el-col>
      <el-col :span="6">
        <el-date-picker
          v-model="value2"
          value-format="yyyy-MM-dd HH:mm:ss"
          type="datetimerange"
          :picker-options="pickerOptions"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          align="right"
        >
        </el-date-picker
      ></el-col>
      <el-col :span="2" :offset="13"
        ><el-button type="primary" @click="searchlog()">查询</el-button></el-col
      >
    </el-row>
    <el-table
      :data="tableData"
      border
      align="center"
      stripe
      style="color: #161718"
    >
      <el-table-column prop="time" label="时间" align="center" width="300">
      </el-table-column>
      <el-table-column prop="content" label="事件" align="center">
      </el-table-column>
    </el-table>
    <el-row class="paging" align="middle" type="flex" justify="end">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage3"
        :page-size="pagesize"
        layout="prev,total, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-row>
  </div>
</template>

<script>
import { systemlog, logpages, seachsystemlog } from "../../api/systemlogapi";
export default {
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      value2: "",
      tableData: [],
      currentPage3: 1,
      pagesize: 12,
      total: 32,
    };
  },
  mounted() {
    this.getsystemlog();
  },
  methods: {
    getsystemlog() {
      systemlog()
        .then((res) => {
          console.log(res);
          this.tableData = res.data.datas;
          this.pagesize = res.data.page_size;
          this.total = res.data.total_pages;
        })
        .catch((err) => {});
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      if (this.value2) {
        seachsystemlog(this.value2[0], this.value2[1], val)
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.datas;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch(() => {
            this.$message.error(e.message);
          });
      } else {
        seachsystemlog("", "", val)
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.datas;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch(() => {
            this.$message.error(e.message);
          });
      }
      // logpages(val)
      //   .then((res) => {
      //     if (res.status == 200) {
      //       console.log(res);
      //       this.tableData = res.data.datas;
      //       this.pagesize = res.data.page_size;
      //       this.total = res.data.total_pages;
      //     }
      //   })
      //   .catch(() => {
      //     this.$message.error(e.message);
      //   });
      // console.log(`当前页: ${val}`);
    },
    searchlog() {
      this.currentPage3=1
      if (this.value2) {
        seachsystemlog(this.value2[0], this.value2[1], "")
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.datas;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch(() => {
            this.$message.error(e.message);
          });
      } else {
        seachsystemlog("", "", "")
          .then((res) => {
            if (res.status == 200) {
              console.log(res);
              this.tableData = res.data.datas;
              this.pagesize = res.data.page_size;
              this.total = res.data.total_pages;
            }
          })
          .catch(() => {
            this.$message.error(e.message);
          });
      }
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 1920px) {
  .syslog {
    padding: 20px 30px 0 30px;
  }
  .paging {
    width: 70%;
    float: right;
    /* background-color: chocolate; */
    text-align: end;
    margin-top: 20px;
  }
  .search {
    background-color: #ebeff5;
    text-align: center;
    border-radius: 10px;

    color: #161718;
  }
  .el-table {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 1080px) {
  .syslog {
    padding: 0.625rem 0.625rem 0 0.625rem;
  }
  .paging {
    width: 70%;
    float: right;
    /* background-color: chocolate; */
    text-align: end;
    margin-top: 0.625rem;
  }
  .search {
    background-color: #ebeff5;
    text-align: center;
    border-radius: 0.625rem;

    color: #161718;
  }
  .el-table {
    margin-top: 0.625rem;
  }
}
</style>