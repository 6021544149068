import service from "../util/link"
import url from "../util/apiurl"

/**
 * 系统日志请求
 */
export function systemlog() {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.systemlog,
            method: "get",

        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function logpages(obj) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.systemlog,
            method: "get",
            params: {
                page: obj
            }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}
export function seachsystemlog(startTime, endTime,page) {
    return new Promise((resolve, reject) => {
        service.request({
            url: url.systemlog,
            method: "get",
            params: {
                startTime: startTime,
                endTime: endTime,
                page: (typeof (page) == 'number') || (typeof (page) == 'string') ? page : ""
            }
        }).then((ok) => {
            resolve(ok)
        }).catch((err) => {
            reject(err)
        })
    })
}